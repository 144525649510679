<template>
  <div>
    <b-row>
      <b-col md="12">
        <validation-observer
          ref="conversionValueForm"
          #default="{invalid, dirty}"
        >
          <b-form
            id="conversionValueForm"
            @submit.prevent="submitConversionValue"
          >
            <b-form-group
              label="Currency: Which currency should be default?"
              label-for="currencySelect"
            >
              <validation-provider
                #default="{ errors }"
                name="Default Currency"
                rules="required"
                vid="currencySelect"
              >
                <v-currency-select
                  id="currencySelect"
                  v-model="selectedCurrency"
                  @input="currencyChanged"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Sales Close Rate: If you had 100 leads, how many would become a customer?"
              label-for="salesCloseRate"
            >
              <validation-provider
                #default="{ errors }"
                name="Sales Close Rate"
                rules="required|integer"
                vid="salesCloseRate"
              >
                <b-form-input
                  id="salesCloseRate"
                  v-model="conversionRateForm.salesCloseRate"
                  type="number"
                  placeholder="25"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Average Sale: What is the typical/average value of a new customer? (No symbols)"
              label-for="averageSales"
            >
              <validation-provider
                #default="{ errors }"
                name="Average Sale"
                rules="required"
                vid="averageSales"
              >
                <b-form-input
                  id="averageSales"
                  v-model="conversionRateForm.averageSales"
                  type="number"
                  placeholder="$1000"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <p>
              Recommended Default Conversion Value: ${{ conversionRate }}
            </p>
            <p>
              <b-button
                type="submit"
                variant="primary"
                class="mb-2"
                :disabled="invalid || !dirty"
              >
                Save Default
              </b-button>
            </p>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vCurrencySelect from 'vue-currency-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,

    // form validation
    ValidationProvider,
    ValidationObserver,

    // currency selector
    vCurrencySelect,
  },

  props: {
    currentUser: {
      type: Object,
      required: true,
    },
    currentDomains: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      userData: this.currentUser,

      // conversion rate form
      conversionRateForm: {
        salesCloseRate: null,
        averageSales: null,
      },
      selectedCurrency: null,
    }
  },
  computed: {
    /*
    * This method calculates the conversion rate
    * conversion rate = average sales * 60% * (sales close rate / 100)
     */
    conversionRate() {
      let conversionRate = 0
      conversionRate = this.conversionRateForm.averageSales * 0.6 * (this.conversionRateForm.salesCloseRate / 100)
      return Number(conversionRate).toFixed(2)
    },
  },
  created() {
    // get userdata
    if (this.userData) {
      this.loadFormData()
    }
  },
  methods: {
    loadFormData() {
      // load data for forms
      this.selectedCurrency = this.userData.currency // load default currency

      if (this.currentDomains.length > 0) { // this.userData.domains.length > 0
        const firstDomain = this.currentDomains[0]
        if (firstDomain.conversion_values) {
          // set conversion values in form
          this.conversionRateForm.salesCloseRate = firstDomain.conversion_values.sales_won_percent
          this.conversionRateForm.averageSales = firstDomain.conversion_values.average_invoice_amount_pennies * 0.01
        }
      }
    },

    /**
     * this method is called when the user clicks the "Save" button on the conversion values step
     * it posts the conversion values (user entered) to the api
     */
    submitConversionValue() {
      // post the conversion values to the api
      const newConversionValues = {
        average_invoice_amount_pennies: Number(this.conversionRateForm.averageSales) * 100, // convert to pennies
        sales_won_percent: Number(this.conversionRateForm.salesCloseRate),
      }
      useJwt.setConversionValue(this.currentDomains[0].id, newConversionValues) // TODO: select correct domain
        .then(() => {
          // update userdata
          // this.refreshUserData()
          this.$emit('updateConversionValue', newConversionValues)

          // confirm success
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Conversion Value Set',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'You have successfully set your conversion value.',
            },
          })
        })
        .catch(error => {
          console.log('submitConversionValue ERROR', error.response.data)
          this.displayErrorMessage(error.response.data.error || error.response.data)
        })
    },

    /**
     * update currency when form changed
     */
    currencyChanged(newValue) {
      // set new default currency
      if (newValue !== this.userData.currency) {
        useJwt.updateDefaultCurrency(newValue)
          .then(response => {
            const responseData = response.data.data
            console.log('updateDefaultCurrency', responseData)
            // this.refreshUserData()
            this.userData.currency = this.selectedCurrency
          })
          .catch(error => {
            console.log(error.response.data)
          })
      }
    },

    displayErrorMessage(errorMessage) {
      const formattedMessage = typeof errorMessage === 'object' && errorMessage !== null ? JSON.stringify(errorMessage) : errorMessage
      // display error message
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'AlertCircleIcon',
          variant: 'danger',
          text: formattedMessage,
        },
      })
    },
  },
}
</script>

<style lang="scss">
</style>
