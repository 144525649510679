var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"conversionValueForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('b-form',{attrs:{"id":"conversionValueForm"},on:{"submit":function($event){$event.preventDefault();return _vm.submitConversionValue.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Currency: Which currency should be default?","label-for":"currencySelect"}},[_c('validation-provider',{attrs:{"name":"Default Currency","rules":"required","vid":"currencySelect"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-select',{attrs:{"id":"currencySelect"},on:{"input":_vm.currencyChanged},model:{value:(_vm.selectedCurrency),callback:function ($$v) {_vm.selectedCurrency=$$v},expression:"selectedCurrency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Sales Close Rate: If you had 100 leads, how many would become a customer?","label-for":"salesCloseRate"}},[_c('validation-provider',{attrs:{"name":"Sales Close Rate","rules":"required|integer","vid":"salesCloseRate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"salesCloseRate","type":"number","placeholder":"25"},model:{value:(_vm.conversionRateForm.salesCloseRate),callback:function ($$v) {_vm.$set(_vm.conversionRateForm, "salesCloseRate", $$v)},expression:"conversionRateForm.salesCloseRate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Average Sale: What is the typical/average value of a new customer? (No symbols)","label-for":"averageSales"}},[_c('validation-provider',{attrs:{"name":"Average Sale","rules":"required","vid":"averageSales"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"averageSales","type":"number","placeholder":"$1000"},model:{value:(_vm.conversionRateForm.averageSales),callback:function ($$v) {_vm.$set(_vm.conversionRateForm, "averageSales", $$v)},expression:"conversionRateForm.averageSales"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('p',[_vm._v(" Recommended Default Conversion Value: $"+_vm._s(_vm.conversionRate)+" ")]),_c('p',[_c('b-button',{staticClass:"mb-2",attrs:{"type":"submit","variant":"primary","disabled":invalid || !dirty}},[_vm._v(" Save Default ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }