<template>
  <div>
    <b-row>

      <b-col md="12">
        <b-form-group
          label="Websites Previously Added:"
        >
          <b-list-group id="domains-list">
            <b-list-group-item
              v-for="domain in domains"
              :key="`domain_${domain.id}`"
            >
              {{ domain.domain }}
              <b-button
                class="float-right"
                size="sm"
                variant="danger"
                @click="deleteUserDomain(domain.id)"
              >
                Delete
              </b-button>
            </b-list-group-item>
            <b-list-group-item
              v-if="domains.length < 1"
            >
              <em>Please add a website below.</em>
            </b-list-group-item>
          </b-list-group>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Add Website Here:"
          label-for="site-url"
        >
          <b-input-group>
            <b-form-input
              id="site-url"
              v-model="newDomainForm.domain"
              placeholder="https://myexamplewebsite.com/"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="submitUserDomain"
              >
                Add Website
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BListGroup,
    BListGroupItem,
  },

  props: {
    currentAgency: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      domains: [],
      newDomainForm: {
        domain: null,
        agency_client_id: null,
      },
    }
  },
  computed: {
    domainFormValid() {
      return this.newDomainForm.domain && this.newDomainForm.agency_client_id
    },
    userData() {
      return this.$store.getters['users/getCurrentUser']
    },
  },
  created() {
    // get userdata
    if (!this.userData) {
      this.refreshUserData()
    } else {
      this.domains = this.userData.domains
    }
  },
  methods: {
    refreshUserData() {
      // refresh userdata
      this.$store.dispatch('users/fetchCurrentUser')
        .then(response => {
          console.log('refreshUserData', response)
          if (this.userData && this.userData.domains.length > 0) {
            // set domains
            this.domains = this.userData.domains
          }
          this.$emit('updateDomains', this.domains)
        })
    },
    submitUserDomain() {
      // post the site tracking url
      this.newDomainForm = { ...this.newDomainForm, agency_client_id: this.currentAgency.id }
      if (this.domainFormValid) {
        useJwt.addUserDomain(this.newDomainForm)
          .then(response => {
            const newDomains = response.data.data
            console.log('addUserDomain', newDomains)

            // update domains in userdata
            this.refreshUserData()

            this.newDomainForm.domain = null // clear form field

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Site Tracking URL Set',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'You have successfully set your ad domain.',
              },
            })
          })
          .catch(error => {
            console.log('addUserDomain ERROR', error.response.data)
            this.displayErrorMessage(error.response.data.error || error.response.data)
          })
      } else {
        // error message
        this.displayErrorMessage('You must complete all form fields.')
      }
    },
    deleteUserDomain(domainId) {
      if (domainId) {
        // confirm alert
        this.$swal({
          title: 'Delete?',
          text: 'This domain will be unassigned from your account.',
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-outline-secondary ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            // delete an assigned domain
            useJwt.deleteUserDomain(domainId)
              .then(response => {
                const newDomains = response.data.data
                console.log('deleteUserDomain', newDomains)

                // update domains in userdata
                this.refreshUserData()

                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Site Tracking URL Set',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'You have successfully deleted a domain.',
                  },
                })
              })
              .catch(error => {
                console.log('deleteUserDomain ERROR', error.response.data)
                this.displayErrorMessage(error.response.data.error || error.response.data)
              })
          }
        })
      }
    },
    displayErrorMessage(errorMessage) {
      const formattedMessage = typeof errorMessage === 'object' && errorMessage !== null ? JSON.stringify(errorMessage) : errorMessage
      // display error message
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'AlertCircleIcon',
          variant: 'danger',
          text: formattedMessage,
        },
      })
    },
  },
}
</script>

<style lang="scss">
</style>
