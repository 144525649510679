<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Management Account"
          label-for="selectedAgency"
        >
          <b-form-select
            id="selectedAgency"
            v-model="selectedAgency"
            :options="agencies"
            value-field="id"
            text-field="name"
            @change="changeSelectedAgency"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="selectedAgency"
        md="6"
      >
        <b-form-group
          label="Google Ads Customer"
          label-for="selectedCustomerId"
        >
          <b-form-select
            id="selectedCustomerId"
            v-model="selectedCustomerId"
            :options="googleCustomers"
            value-field="customer_id"
            text-field="customer_descriptive_name"
            @change="verifySelectedCustomer"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BFormGroup,
  BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
  },
  props: {
    modelValue: {
      type: [Number, String, Object],
      default: 0,
    },
  },
  data() {
    return {
      agencies: [],
      currentAgency: {},
      selectedAgency: null, // form field

      googleCustomers: [],
      currentCustomer: {},
      selectedCustomerId: null, // form field
    }
  },
  created() {
    this.getAgencyClients()
    this.getAdCustomers()
  },
  methods: {
    getAgencyClients() {
      // get agency clients
      useJwt.getAgencyClients()
        .then(response => {
          const agencyData = response.data.data
          console.log('getAgencyClients', agencyData)
          this.agencies = agencyData
          if (agencyData.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            this.currentAgency = agencyData[0] // TODO: SELECT OTHER AGENCY
            this.setAgency(this.currentAgency)

            if (this.currentAgency.google_customer_id) {
              this.setCustomerId(this.currentAgency.google_customer_id)
            }
          }
        })
        .catch(error => {
          console.log('getAgencyClients ERROR', error.response)
        })
    },
    getAdCustomers() {
      // get data of logged in user
      useJwt.getAdCustomers()
        .then(response => {
          const responseData = response.data.data
          this.googleCustomers = responseData
          console.log('getAdCustomers', responseData)
          if (!this.selectedCustomerId && this.googleCustomers.length > 0) {
            // set default customer if none is set
            this.changeSelectedCustomer(this.googleCustomers[0])
          }
          if (this.selectedCustomerId) {
            // emit google customer data
            const selectedCustomer = this.googleCustomers.find(item => item.customer_id === this.selectedCustomerId)
            this.currentCustomer = selectedCustomer
            this.setCustomer(selectedCustomer)
          }
        })
        .catch(error => {
          console.log('getAdCustomers ERROR', error.response.data)
        })
    },
    changeSelectedAgency(newValue) {
      console.log('changeSelectedAgency', newValue)
      const selectedAgency = this.agencies.find(item => item.id === newValue)
      this.currentAgency = selectedAgency
      console.log('currentAgency', this.currentAgency)
      this.setCustomerId(this.currentAgency.google_customer_id)
    },
    changeSelectedCustomer(customerData) {
      console.log('changeSelectedCustomer', customerData)
      this.setCustomer(customerData)
      this.setCustomerId(customerData.google_customer_id)
      if (customerData.agency_id && customerData.google_customer_id) {
        // set new customer
        useJwt.updateAgencyCustomer(customerData)
          .then(() => {
            this.getAgencyClients() // refresh clients list
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Google Customer Updated',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Your Google customer has been updated.',
              },
            })
          })
          .catch(error => {
            console.log(error.response.data)
            // this.showErrorMessage(error.response.data.error)
            this.showErrorModal(error.response.data.error)
          })
      } else {
        console.log('changeSelectedCustomer: No action taken.')
      }
    },
    verifySelectedCustomer(customerId) {
      // pre-flight check to see if Google Ads account has the proper credentials
      const selectedCustomer = this.googleCustomers.find(item => item.customer_id === customerId)
      console.log('selectedAgency', this.currentAgency)
      console.log('selectedCustomer', selectedCustomer)
      if (this.selectedAgency && (this.currentAgency.google_customer_id !== selectedCustomer.customer_id)) {
        const customerData = {
          agency_id: this.currentAgency.id,
          google_customer_id: selectedCustomer.customer_id,
        }

        // set new customer
        useJwt.verifyAgencyCustomer(customerData)
          .then(response => {
            console.log('verifyAgencyCustomer', response)
            this.changeSelectedCustomer(customerData)
          })
          .catch(error => {
            console.log('verifySelectedCustomer ERROR', error.response.data)
            const errorMessage = error.response.data.error || error.response.data.message
            this.showErrorModal(errorMessage)
            this.selectedCustomerId = null // reset selectedCustomerId field because it's invalid
          })
      }
    },
    setCustomer(newCustomer) {
      console.log('setCustomer', newCustomer)
      this.$emit('setCurrentCustomer', newCustomer)
    },
    setCustomerId(newCustomerId) {
      console.log('setCustomerId', newCustomerId)
      this.selectedCustomerId = newCustomerId
      this.$emit('setCurrentCustomerId', newCustomerId)
    },
    setAgency(newAgency) {
      console.log('setAgency', newAgency.id)
      this.selectedAgency = newAgency.id
      this.$emit('setCurrentAgency', newAgency)
    },

    showErrorMessage(errorMessage) {
      this.$toast(
        {
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: errorMessage,
          },
        },
        {
          timeout: false,
        },
      )
    },

    showErrorModal(errorMessage) {
      const cleanErrorMessage = this.$sanitize(errorMessage)
      this.$swal({
        title: 'Error',
        icon: 'error',
        html: cleanErrorMessage,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
