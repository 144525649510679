<template>
  <div>
    <b-row>

      <b-col
        v-if="googleAccess"
        md="12"
      >
        <!-- logged in google -->
        <b-form-group
          label="Linked Google Ads Account"
        >
          <b-list-group id="google-auth-list">
            <b-list-group-item
              v-for="googleUser in googleAccess"
              :key="`user_${googleUser.google_id}`"
              class="d-flex align-items-center"
            >
              <b-avatar
                :src="googleUser.avatar"
                class="mr-2"
              />
              <span class="mr-auto">{{ googleUser.email }}</span>
              <b-button
                class="float-right"
                size="sm"
                variant="outline-danger"
                @click="showGoogleAuth"
              >
                Re-link Google Account
              </b-button>
            </b-list-group-item>
          </b-list-group>
        </b-form-group>
      </b-col>
      <b-col
        v-if="!googleAccess"
        md="12"
      >
        <b-form-group
          label="Link a Google Ads Account"
          label-for="google-auth-list"
        >
          <!-- google button -->
          <b-link
            v-if="redirectUrl"
            :href="redirectUrl"
          >
            <b-button
              variant="default"
              class="mb-2 login-with-google-btn"
            >
              Sign in with Google
            </b-button>
          </b-link>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <div v-if="!redirectUrl">
          <strong>Error!</strong> Unable to connect to Google.
          Please try again later or <b-link to="https://converifai.freshdesk.com/support/tickets/new">
            contact Support
          </b-link>.
        </div>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BAvatar,
  BButton,
  BFormGroup,
  BLink,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BButton,
    BFormGroup,
    BLink,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      // google auth
      redirectUrl: null,
    }
  },
  computed: {
    userData() {
      return this.$store.getters['users/getCurrentUser']
    },
    googleAccess() {
      let googleAccess = null
      if (this.userData && this.userData.google_access && this.userData.google_accounts.length > 0) {
        googleAccess = this.userData.google_accounts
      }
      return googleAccess
    },
  },
  created() {
    // get login url for google oauth
    this.getLoginUrl()
  },
  methods: {
    getLoginUrl() {
      // get redirect url for google login
      useJwt.getLoginUrl()
        .then(response => {
          const redirectData = response.data.data
          this.redirectUrl = redirectData.url
        })
        .catch(error => {
          console.log('getLoginUrl ERROR', error.response)
        })
    },
    showGoogleAuth() {
      // show google auth button
      if (this.userData) {
        this.userData.google_access = false
      }
    },
  },
}
</script>

<style lang="scss">
.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}

.login-with-google-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.login-with-google-btn:active {
  background-color: #eeeeee;
}

.login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc;
}

.login-with-google-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
</style>
